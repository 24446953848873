.articles-container {
  position: relative;
  overflow: hidden;
}
.articles-header {
  z-index: 2;
  position: relative;
}
.articles-framework-img {
  position: absolute;
  opacity: 10%;
  object-fit: contain;
  object-position: center;
  top: 48px;
  z-index: 1;
  width: 400px;
}
.articles-framework-img-1 {
  @extend .articles-framework-img;
  left: 115px;
}
.articles-framework-img-3 {
  @extend .articles-framework-img;
  left: 715px;
  width: 450px;
  top: -30px;
}
.tags {
  background-color: $light;
  padding: 2rem 2rem 1rem 2rem;
}
.tag {
  background-color: $purple;
  color: $light;
  &.active {
    background-color: $green;
  }
  &:hover {
    background-color: darken($purple, 10%);
    cursor: pointer;
  }
  &.active:hover {
    background-color: darken($green, 10%);
    cursor: pointer;
  }
  transition: all 150ms;
  padding: 0rem 2rem;
  display: inline-block;
}
.remove-tag-icon {
  line-height: 10px;
  &:before {
    font-size: 14px;
  }
  pointer-events: none;
}
#articles {
  position: relative;
}
.article-item {
  opacity: 0;
}
.article-content {
  padding-top: 0.5rem;
}

.article-title {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.article-title-link {
  &:not(:hover) {
    color: $dark;
  }
  &:hover {
    color: $cyan;
  }
}

.article-excerpt {
  text-align: justify;
}
.article-tags {
  span {
    color: $cyan;
  }
  & i {
    &:before {
      color: $green;
    }
  }
}
.btn-search {
  border-radius: 3px;
  padding: 0.675rem 1.5rem 0.675rem 2rem;
  position: relative;
  border-radius: 2px;

  &:hover, &:active {
    background-color: #1ca6b2;
    border-color: #1ca6b2;
    color: #000;
  }
  
}
.btn-search-padding {
  padding-right: 21px;
}
.btn-search:after {
  content: "";
  position: absolute;
  right: -21px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #1ca6b2;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
#search-articles-input {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border-radius: 3px;
}
.article-date {
  font-size: 12px;
  margin-bottom: 0.5rem;
  color: $purple;
}

.article-background {
  position: relative;
}
.article-featured-image {
  width: 100%;
  height: calc(60vh - 80px);
  object-fit: cover;
  object-position: center;
}
.article-content-wrapper {
  margin-top: -150px;
}
.article-white-background {
  background-color: $white;
  margin: -3rem;
  padding: 3rem;
}

.guide-title {
  font-size: 2rem;
}

@include media-breakpoint-down(lg) {
  .article-content-wrapper {
    margin-top: -75px;
  }
}
@include media-breakpoint-down(md) {
  .article-content-wrapper {
    margin-top: 0px;
  }
  .article-white-background {
    margin: 0;
    padding: 0;
  }
}

.article-card-tag-item {
  color: $cyan;
  &:hover {
    color: $dark;
  }
  &:active {
    color: $dark;
  }
  cursor: pointer;
}

.link-like {
  &:not(.liked) {
    .bi-bookmark-check-fill {
      display: none;
    }
  }
  &.liked {
    .bi-bookmark-plus {
      display: none;
    }
  }
}

.share a:hover{
  text-decoration: none !important;
}

.btn-download {
  border-radius: 5px;
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
}

@include media-breakpoint-down(md) {
  .btn-download-mobile {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.card-download {
  p {
    margin-bottom: 5px;
  }
  ul {
    list-style-type: disc;
    margin-left: 30px;
    line-height: 1.5;
  }
}

.section-title {
  font-size: 36px;
}

.guides-container {
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: top;
}

.mt-6 {
  margin-top: 3rem;
}

@include media-breakpoint-up(lg) {
  .mt-6 {
    margin-top: 5rem;
  }
}

.container-xs {
  max-width: 580px;
}

.testionials {
  blockquote {
    margin-bottom: 0.5rem;
    p {
      display: inline;
    }
  }
  blockquote, cite {
    font-size: 1.5rem;
    line-height: 1.3;
  }
}

.card-testimonial {
  &:not(:first-child)  {
    margin-top: 1.5rem;
  }
  .content {
    background-color: #fff;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.odd .content {
    padding: 2rem;
  }

  &.even .content {
    padding: 2rem;
  }

  .image {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-up(md) {
    &:not(:first-child)  {
      margin-top: 3rem;
    }  
    &.odd .content {
      margin-left: -100px;
      padding-left: 150px;
      padding-right: 5rem;
    }

    &.even .content {
      margin-right: -100px;
      padding-right: 150px;
      padding-left: 5rem;
      max-width: 1032px;
    }
  }

  @include media-breakpoint-down(md) {
    .image {
      display: none;
    }
  }
}

.about {
  max-width: 842px;
  margin: 0 auto;

  .content {
    font-size: 1.375rem;

    @include media-breakpoint-up(md) {
      font-size: 1.875rem;
    }

    ul {
      padding-left: 2em;
      list-style-type: disc;
      margin-bottom: 1.5rem;
    }  
    p {
      margin-bottom: 1.5rem;
    }
  }
}

.directmail-main-table-container {
  width: 100% !important;
  padding: 16px !important;
  box-shadow: none !important;
  border-radius: 5px;

  .newsletter-input {
    padding: 10px !important;
    background-color: #EFEFEF !important;
    border-color: #EFEFEF !important;
  }
  .newsletter-submit {
    // padding: 10px !important;
    height: 42px !important;
  }
}

.home-download {
  
  @include media-breakpoint-up(lg) {
    display: inline-block;
  }

  .text {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &:before {
      content: "";
      background-image: url("/assets/images/arrow-download.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      position: absolute;
      top: 0;
      left: -70px;
      bottom: -50px;
      right: 0;
    }
  }
}

@include media-breakpoint-down(lg) {
  .hero-home {
    background-image: none !important;
    background-color: $green;
  }
  .hero-name {
    max-width: 450px;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(lg) {
  .hero-name {
    max-width: 400px;
    padding-bottom: 8rem;
  }
  .nick-black-title {
    line-height: 0.9;
  }
}

.bi-spin {
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
  height: 37px;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.input-spinner {
  height: 52px;
  width: 52px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

