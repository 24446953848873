@import 'functions';
@import 'fonts';
@import 'bootstrap-custom';
@import 'animate.css/animate.min';
@import 'wowjs/css/libs/animate';
@import 'typography';
@import 'buttons';
@import 'navbar';
@import 'hero';
@import 'markdown';
@import 'articles';
@import 'home';
@import 'talks';
@import 'coaching';

html,
body {
  height: 100vh;
  font-family: 'aeonik';
  font-size: 18px;
}

body {
  overflow-x: hidden;
}

main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

footer {
  flex-shrink: 0;
  background: $dark;
  padding: 2rem 0;
  color: $light;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: $cyan;
  transition: all 300ms;
  text-decoration: none;

  &:hover {
    color: darken($cyan, 10%);
    text-decoration: none;
  }
}

b,
strong {
  font-weight: normal;
  font-family: 'aeonik-bold';
}

em {
  font-style: normal;
  font-family: 'aeonik-light-italic';
}

.default-pseudo {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.social-link {
  a {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light;
    text-decoration: none;
    position: relative;

    &:hover {
      text-decoration: none;
    }

    &:after {
      @extend .default-pseudo;
      background: $dark;
      opacity: 0;
      transition: all 300ms;
    }

    &:hover:after {
      opacity: 0.1;
    }

    & i {
      z-index: 2;
    }
  }
}

.absolute-in-front {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.form-control::placeholder {
  color: #d1d1d1;
}

.btn-hover-highlight {
  &:hover {
    background-color: darken($highlight, 5%);
  }
}

.wow {
  visibility: hidden;
}

.bg-transparent {
  background-color: transparent !important;
}

.clickable {
  cursor: pointer;
}

.non-scrollable {
  overflow: hidden;
}

.btn-close-video-playback {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 2.5rem;
  margin-top: 2.5rem;
  color: $white;
}

@include media-breakpoint-down(sm) {
  .btn-close-video-playback {
    margin-right: 1rem;
    margin-top: 1rem;
  }
}
