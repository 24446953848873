.hero-wall {
  background-color: $dark;
  color: $white;
  padding: 2rem 1rem;
  background-position: top;
  background-size: cover;
  transition: all 300s;
  background-repeat: no-repeat;
}

@include media-breakpoint-up(lg) {
  .hero-wall {
    padding: 4rem;
  }
}
